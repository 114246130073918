import * as React from "react";
import Logo from "./images/logos/Logo.png";

const Terms = () => {
  return (
    <div className="MaxContainer">
      <div className="MoodzhubTop" id="home">
        <div className="MainLogo">
          <img src={Logo} alt="app-logo" className="LogoImage" />
        </div>
      </div>
      <div className="MaxWidthContainer">
        <div data-custom-class="body">
          <div>
            <strong>
              <span style={{ fontSize: '26px' }}><span data-custom-class="title">PRIVACY NOTICE</span></span>
            </strong>
          </div>
          <div><br /></div>
          <div>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <strong>
                <span style={{ fontSize: '15px' }}><span data-custom-class="subtitle">Last updated <bdt className="question">August 22, 2022</bdt></span></span>
              </strong>
            </span>
          </div>
          <div><br /></div>
          <div><br /></div>
          <div><br /></div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                <span data-custom-class="body_text">This privacy notice for
                  <bdt className="question">Tycoon Tycoon Company Limited</bdt>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text"><bdt className="block-component" /></span>
                  </span>
                  (
                  <bdt className="block-component" />"
                  <bdt className="block-component" /><strong>Company</strong>
                  <bdt className="statement-end-if-in-editor" />," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"
                  <bdt className="statement-end-if-in-editor" />
                </span>
                <span data-custom-class="body_text">
                  ), describes how and why we might collect, store, use, and/or share (
                  <bdt className="block-component" />"<strong>process</strong>"
                  <bdt className="statement-end-if-in-editor" />) your information when you use our services (
                  <bdt className="block-component" />"<strong>Services</strong>"
                  <bdt className="statement-end-if-in-editor" />), such as when you:
                </span>
              </span>
            </span>
            <span style={{ fontSize: '15px' }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text"><bdt className="block-component" /></span>
                  </span>
                </span>
              </span>
            </span>

            <div>
              <bdt className="block-component">
                <span style={{ fontSize: '15px' }}>
                  <span style={{ fontSize: '15px' }}>
                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text"><bdt className="block-component" /></span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </bdt>
            </div>

            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">Download and use
                      <bdt className="block-component" /> our mobile application
                      <bdt className="block-component" /> (
                      <bdt className="question">Moodz Hub - Mood Tracker Diary)
                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: '15px' }}>
                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: '15px' }}>
                                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                                        <bdt className="statement-end-if-in-editor">,</bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: '15px' }}>
                              <span style={{ color: 'rgb(89, 89, 89)' }}>
                                <bdt className="statement-end-if-in-editor">
                                  <bdt className="block-component"> or any other application of ours that links to this privacy notice</bdt>
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>

            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>

            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or events
                      <span style={{ fontSize: '15px' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: '15px' }}>
                              <span style={{ color: 'rgb(89, 89, 89)' }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>

            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <strong>Questions or concerns? </strong>
                    Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at
                    <bdt className="question">hello@moodzhub.com</bdt>.
                  </span>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <strong>
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                </span>
              </strong>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>
                      This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click
                    </em>
                  </strong>
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">
                    <strong><em>here</em></strong>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong><em> to go directly to our table of contents.</em></strong>
                </span>
              </span>
            </div>

            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>What personal information do we process?</strong>
                  When you visit, use, or navigate our Services, we may process personal information depending on how you interact with
                  <bdt className="block-component" />
                  <bdt className="question">Tycoon Tycoon Company Limited</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  and the Services, the choices you make, and the products and features you use. Click
                </span>
              </span>
              <a data-custom-class="link" href="#personalinfo">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text"> to learn more.</span>
              </span>
            </div>

            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>Do we process any sensitive personal information?</strong>
                  <bdt className="block-component" />
                  We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Click
                </span>
              </span>
              <a data-custom-class="link" href="#sensitiveinfo">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text"> to learn more.
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>

            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>Do we receive any information from third parties?</strong>
                  <bdt className="block-component" />
                  We do not receive any information from third parties.
                  <bdt className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>How do we process your information?</strong>
                  We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click
                </span>
              </span>
              <a data-custom-class="link" href="#infouse">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text"> to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>In what situations and with which
                    <bdt className="block-component" />
                    parties do we share personal information?
                  </strong>
                  We may share information in specific situations and with specific
                  <bdt className="block-component" />
                  third parties. Click
                </span>
              </span>
              <a data-custom-class="link" href="#whoshare">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text"> to learn more.
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>How do we keep your information safe?</strong>
                  We have <bdt className="block-component" />organizational
                  <bdt className="statement-end-if-in-editor" />
                  and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                  <bdt className="block-component" />unauthorized
                  <bdt className="statement-end-if-in-editor" />
                  third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click
                </span>
              </span>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  to learn more.<bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>What are your rights?</strong>
                  Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click
                </span>
              </span>
              <a data-custom-class="link" href="#privacyrights">
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"> to learn more.</span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>

            {/* spp here */}

            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by filling out our data subject request form available <bdt className="block-component" /></span></span>
              <a data-custom-class="link" href="https://app.termly.io/notify/32eb505c-e779-4dfc-9981-727d1965f12c" rel="noopener noreferrer" target="_blank"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span>
              </a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" />, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">Want to learn more about what <bdt className="block-component" /><bdt className="question">Tycoon Tycoon Company Limited</bdt><bdt className="statement-end-if-in-editor" /> does with any information we collect? Click </span></span>
              <a data-custom-class="link" href="#toc"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span>
              </a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"> to review the notice in full.</span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="toc" style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#infocollect"><span style={{ color: 'rgb(89, 89, 89)' }}>1. WHAT INFORMATION DO WE COLLECT?</span></a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#infouse"><span style={{ color: 'rgb(89, 89, 89)' }}>2. HOW DO WE PROCESS YOUR INFORMATION?<bdt className="block-component" /></span></a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#legalbases"><span style={{ color: 'rgb(89, 89, 89)' }}>3. <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</span></span>
              <bdt className="statement-end-if-in-editor" />
            </span>
            </a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><a data-custom-class="link" href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a href="#3pwebsites"><span data-custom-class="link">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?<bdt className="statement-end-if-in-editor" /></span></a><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
              <span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
              </span>
              </span>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#sociallogins"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></span>
            </span>
            </a><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span></span>
              <bdt className="block-component" />
            </span>
            </span>
            </span>
              </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#inforetain"><span style={{ color: 'rgb(89, 89, 89)' }}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
            </span>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#infosafe"><span style={{ color: 'rgb(89, 89, 89)' }}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /><bdt className="block-component" /></span></span>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><a data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#DNT"><span style={{ color: 'rgb(89, 89, 89)' }}>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#caresidents"><span style={{ color: 'rgb(89, 89, 89)' }}>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#policyupdates"><span style={{ color: 'rgb(89, 89, 89)' }}>12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><a data-custom-class="link" href="#contact"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
            <div style={{ lineHeight: '1.5' }}><a data-custom-class="link" href="#request"><span style={{ color: 'rgb(89, 89, 89)' }}>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="infocollect" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong>
            </span>
            </span>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="personalinfo" style={{ lineHeight: '1.5' }}><span data-custom-class="heading_2" style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><strong>Personal information you disclose to us</strong></span></span>
            </div>
            <div>
              <div><br /></div>
              <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span>
              </span>
              </span><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em> </em></strong><em>We collect personal information that you provide to us.</em></span></span>
              </span>
                </span>
              </span>
              </span>
              </div>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span style={{ fontSize: '15px' }}><bdt className="block-component" /></span>register
              on the Services, </span><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="statement-end-if-in-editor" /></span></span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:<span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span></span>
            </span>
            </span>
            </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">names</bdt></span></span>
              </span>
              </span>
              </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span></span>
            </span>
            </span>
            </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">email addresses</bdt></span></span>
              </span>
              </span>
              </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span></span>
            </span>
            </span>
            </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">usernames</bdt></span></span>
              </span>
              </span>
              </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span></span>
            </span>
            </span>
            </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">passwords</bdt></span></span>
              </span>
              </span>
              </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            </span>
            </span>
            </span>
            </div>
            <div id="sensitiveinfo" style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>Sensitive Information.</strong> <bdt className="block-component" />When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:<bdt className="forloop-component" /></span></span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">health data</bdt></span></span>
              </li>
            </ul>
            <div><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="forloop-component" /></span><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component"><bdt className="block-component" /></bdt></span></span>
            </span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Social Media Login Data. </strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, or other social media account. If you choose to register in this way, we will collect the information described in the section called <bdt className="block-component" />"<bdt className="statement-end-if-in-editor" /><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><a data-custom-class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></span></span>
            </span>
            </span>
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" /> below.</span>
            </span>
            </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="statement-end-if-in-editor"><bdt className="statement-end-if-in-editor" /></bdt></span></span>
            </span>
            </span>
              <bdt className="block-component">
                <bdt className="block-component" />
              </bdt>
            </div>
            <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:<bdt className="block-component" /></span></span>
              <div style={{ lineHeight: '1.5' }}>
                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><em>Mobile Device Access.</em> We may request access or permission to certain features from your mobile device, including your mobile device's <bdt className="forloop-component" /><bdt className="question">social media accounts</bdt>, <bdt className="forloop-component" />and other features. If you wish to change our access or permissions, you may do so in your device's settings.<bdt className="statement-end-if-in-editor" /></span></span>
                </li>
              </ul>
              <div style={{ lineHeight: '1.5' }}>
                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                <div style={{ lineHeight: '1.5' }}>
                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                  </bdt>
                </div>
                <ul>
                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.<bdt className="statement-end-if-in-editor" /></span></span>
                  </li>
                </ul>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span></span>
                </div>
                <div style={{ lineHeight: '1.5' }}><br /></div>
                <div style={{ lineHeight: '1.5' }}>
                  <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                  </bdt>
                </div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></span>
                </span>
                </div>
                <div style={{ lineHeight: '1.5' }}><br /></div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                </span>
                </div>
                <div style={{ lineHeight: '1.5' }}><span data-custom-class="heading_2" style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><strong>Information automatically collected</strong></span></span>
                </div>
                <div>
                  <div><br /></div>
                  <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span>
                  </span>
                  </span><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em> </em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em></span></span>
                  </span>
                    </span>
                  </span>
                  </span>
                  </div>
                </div>
                <div style={{ lineHeight: '1.5' }}><br /></div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></span>
                </span>
                </div>
                <div style={{ lineHeight: '1.5' }}><br /></div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span>
                </span><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                </div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">The information we collect includes:<bdt className="block-component" /></span></span>
                </span>
                </div>
                <ul>
                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services<span style={{ fontSize: '15px' }}> </span>(such
                    as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called
                    <bdt className="block-component" />"crash dumps"
                    <bdt className="statement-end-if-in-editor" />), and hardware settings).<span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span></span>
                    </span>
                    </span>
                    </span>
                  </span>
                  </span>
                  </span>
                  </li>
                </ul>
                <div style={{ lineHeight: '1.5' }}>
                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                  </bdt>
                </div>
                <ul>
                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.<span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span></span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </li>
                </ul>
                <div style={{ lineHeight: '1.5' }}>
                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                  </bdt>
                </div>
                <ul>
                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.<span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span></span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </li>
                </ul>
                <div>
                  <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt><bdt className="statement-end-if-in-editor" /><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor"><bdt className="block-component" /></bdt></span></span>
                  </span>
                  </span>
                  </span>
                  <span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                </div>
                <div id="infouse" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong>
                </span>
                </span>
                </span>
                </span>
                </span>
                </div>
                <div>
                  <div style={{ lineHeight: '1.5' }}><br /></div>
                  <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </div>
                </div>
                <div style={{ lineHeight: '1.5' }}><br /></div>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong><bdt className="block-component" /></span></span>
                </span>
                </div>
                <ul>
                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise manage user accounts. </strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </span>
                  </li>
                </ul>
                <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                </span>
                  <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                  </span>
                    <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                    </span>
                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                      </span>
                        <div style={{ lineHeight: '1.5' }}>
                          <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                          <div style={{ lineHeight: '1.5' }}>
                            <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                            <div style={{ lineHeight: '1.5' }}>
                              <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                              <div style={{ lineHeight: '1.5' }}>
                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                </bdt>
                                <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                  <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                </p><p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                  <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                </p><p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                  <bdt className="block-component" />
                                </p><p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                  <bdt className="block-component" />
                                </p><div style={{ lineHeight: '1.5' }}>
                                  <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                  <div style={{ lineHeight: '1.5' }}>
                                    <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                    <div style={{ lineHeight: '1.5' }}>
                                      <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><bdt className="block-component"><span data-custom-class="body_text" /></bdt>
                                      </span>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>To deliver targeted advertising to you.</strong> We may process your information to develop and display <bdt className="block-component" />personalized<bdt className="statement-end-if-in-editor" /> content and advertising tailored to your interests, location, and more.<bdt className="block-component" /></span>
                                          <bdt className="block-component"><span data-custom-class="body_text" /></bdt>
                                        </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: '1.5' }}>
                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                        <div style={{ lineHeight: '1.5' }}>
                                          <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                          </bdt>
                                          <div style={{ lineHeight: '1.5' }}>
                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                            </bdt>
                                            <div style={{ lineHeight: '1.5' }}>
                                              <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                              </bdt>
                                              <div style={{ lineHeight: '1.5' }}>
                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                </bdt>
                                                <div style={{ lineHeight: '1.5' }}>
                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                  </bdt>
                                                  <div style={{ lineHeight: '1.5' }}>
                                                    <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                    </bdt>
                                                    <div style={{ lineHeight: '1.5' }}>
                                                      <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                      <div style={{ lineHeight: '1.5' }}>
                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                        <div style={{ lineHeight: '1.5' }}>
                                                          <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                          <div style={{ lineHeight: '1.5' }}>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></span>
                                                              <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div style={{ lineHeight: '1.5' }}>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                            </bdt>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                            </bdt>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                            </bdt>
                                                          </div>
                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                          <div id="legalbases" style={{ lineHeight: '1.5' }}><strong><span style={{ fontSize: '15px' }}><span data-custom-class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></span></strong></div>
                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                          <div style={{ lineHeight: '1.5' }}><em><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>In Short: </strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or <bdt className="block-component" />fulfill<bdt className="statement-end-if-in-editor" /> our contractual obligations, to protect your rights, or to <bdt className="block-component" />fulfill<bdt className="statement-end-if-in-editor" /> our legitimate business interests.</span></span></em>
                                                            <span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                          </div>
                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                          <div style={{ lineHeight: '1.5' }}><em><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong><u>If you are located in the EU or UK, this section applies to you.</u></strong></span></span></em>
                                                            <span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                          </div>
                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></span>
                                                          </div>
                                                          <ul>
                                                            <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>Consent. </strong>We may process your information if you have given us permission (i.e.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click </span></span>
                                                              <a data-custom-class="link" href="#withdrawconsent"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span>
                                                              </a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"> to learn more.</span></span>
                                                            </li>
                                                          </ul>
                                                          <div style={{ lineHeight: '1.5' }}>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                            </bdt>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                              <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                              </bdt>
                                                            </div>
                                                            <ul>
                                                              <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</span></span>
                                                              </li>
                                                            </ul>
                                                            <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                              </div>
                                                              <ul style={{ marginLeft: '40px' }}>
                                                                <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>Develop and display <bdt className="block-component" />personalized<bdt className="statement-end-if-in-editor" /> and relevant advertising content for our users<bdt className="statement-end-if-in-editor" /></span></span>
                                                                </li>
                                                              </ul>
                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                  <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                    <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                        <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.<bdt className="statement-end-if-in-editor" /><br /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></span>
                                                                          <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                          </bdt>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong><u><em>If you are located in Canada, this section applies to you.</em></u></strong><bdt className="statement-end-if-in-editor" /></span></span>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>We may process your information if you have given us specific permission (i.e.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> implied consent). You can withdraw your consent at any time. Click </span></span>
                                                                        <a data-custom-class="link" href="#withdrawconsent"><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>here</span></span>
                                                                        </a><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}> to learn more.</span></span>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>For investigations and fraud detection and prevention<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>For business transactions provided certain conditions are met</span></span>
                                                                          <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                          </bdt>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></span>
                                                                          <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                          </bdt>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>For identifying injured, ill, or deceased persons and communicating with next of kin</span></span>
                                                                          <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                          </bdt>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">If the collection is solely for journalistic, artistic, or literary purposes<bdt className="statement-end-if-in-editor" /></span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">If the information is publicly available and is specified by the regulations</span>
                                                                          <bdt className="statement-end-if-in-editor"><span data-custom-class="body_text" /></bdt>
                                                                        </span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                        </bdt>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div id="whoshare" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong>
                                                                      </span>
                                                                      </span>
                                                                      </span>
                                                                      </span>
                                                                      </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We may share information in specific situations described in this section and/or with the following <bdt className="block-component" />third parties.</em></span></span>
                                                                      </span>
                                                                      </div>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span></span></div>
                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">We <bdt className="block-component" />may need to share your personal information in the following situations:</span></span>
                                                                      </div>
                                                                      <ul>
                                                                        <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span>
                                                                        </li>
                                                                      </ul>
                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                        <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><bdt className="block-component"><span data-custom-class="body_text" /></bdt>
                                                                        </span>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                            </bdt>
                                                                            <div style={{ lineHeight: '1.5' }}>
                                                                              <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                              <div style={{ lineHeight: '1.5' }}>
                                                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><strong>Offer Wall. </strong>Our application(s) may display a third-party hosted <bdt className="block-component" />"offer wall."<bdt className="statement-end-if-in-editor" /> Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                  <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                  </bdt>
                                                                                </li>
                                                                              </ul>
                                                                              <div style={{ lineHeight: '1.5' }}>
                                                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><strong><span id="3pwebsites" style={{ fontSize: '15px' }}><span data-custom-class="heading_1">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></span></strong></div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</em></span></span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">The Services<bdt className="block-component" /><bdt className="block-component" />, including our offer wall,<bdt className="statement-end-if-in-editor" /><bdt className="statement-end-if-in-editor" /> may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</span></span>
                                                                                <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component"><span data-custom-class="heading_1" /></bdt></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                <span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="sociallogins" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</em></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.<span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span>
                                                                                <bdt className="block-component"><span data-custom-class="body_text"><bdt className="block-component" /></span></bdt></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="inforetain" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We keep your information for as long as necessary to <bdt className="block-component" />fulfill<bdt className="statement-end-if-in-editor" /> the purposes outlined in this privacy notice unless otherwise required by law.</em></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                                <bdt className="block-component" />the period of time in which users have an account with us
                                                                                <bdt className="block-component" /><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="else-block" /></span></span>
                                                                                </span>.</span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or <bdt className="block-component" />anonymize<bdt className="statement-end-if-in-editor" /> such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="infosafe" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We aim to protect your personal information through a system of <bdt className="block-component" />organizational<bdt className="statement-end-if-in-editor" /> and technical security measures.</em></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We have implemented appropriate and reasonable technical and <bdt className="block-component" />organizational<bdt className="statement-end-if-in-editor" /> security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <bdt className="block-component" />unauthorized<bdt className="statement-end-if-in-editor" /> third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.<span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span>
                                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="privacyrights" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em> <span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><em><bdt className="block-component" /></em></span></span>
                                                                              </span>In some regions, such as
                                                                                <bdt className="block-component" />the European Economic Area (EEA), United Kingdom (UK), and Canada
                                                                                <bdt className="block-component" />, you have rights that allow you greater access to and control over your personal information.<span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><em><bdt className="statement-end-if-in-editor" /></em></span></span>
                                                                                </span>You may review, change, or terminate your account at any time.</em><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">In some regions (like <bdt className="block-component" />the EEA, UK, and Canada<bdt className="block-component" />), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <bdt className="block-component" />"<bdt className="statement-end-if-in-editor" /></span></span>
                                                                              </span><a data-custom-class="link" href="#contact"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a>
                                                                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />"<bdt className="statement-end-if-in-editor" /> below.</span></span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws.</span>
                                                                                <span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}> </span></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(48, 48, 241)' }}><span data-custom-class="body_text"><a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank"><span style={{ fontSize: '15px' }}>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>.</span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are located in Switzerland, the contact details for the data protection authorities are available here: <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(48, 48, 241)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>.</span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="withdrawconsent" style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information,<bdt className="block-component" /> which may be express and/or implied consent depending on the applicable law,<bdt className="statement-end-if-in-editor" /> you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <bdt className="block-component" />"<bdt className="statement-end-if-in-editor" /></span></span>
                                                                              </span><a data-custom-class="link" href="#contact"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a>
                                                                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />"<bdt className="statement-end-if-in-editor" /> below<bdt className="block-component" />.</span></span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,<bdt className="block-component" /> when applicable law allows,<bdt className="statement-end-if-in-editor" /> will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<bdt className="block-component" /></span></span>
                                                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="heading_2"><strong>Account Information</strong></span></span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If you would at any time like to review or change the information in your account or terminate your account, you can:<bdt className="forloop-component" /></span></span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></span>
                                                                                <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}>If you have questions or comments about your privacy rights, you may email us at <bdt className="question">hello@moodzhub.com</bdt>.</span></span>
                                                                                <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span>
                                                                                </bdt>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="DNT" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<bdt className="block-component" />"DNT"<bdt className="statement-end-if-in-editor" />) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <bdt className="block-component" />recognizing<bdt className="statement-end-if-in-editor" /> and implementing DNT signals has been <bdt className="block-component" />finalized<bdt className="statement-end-if-in-editor" />. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="caresidents" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short: </em></strong><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the <bdt className="block-component" />"Shine The Light"<bdt className="statement-end-if-in-editor" /> law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> backups, etc.).<span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="heading_2" style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><strong>CCPA Privacy Notice</strong></span></span>
                                                                              </div>
                                                                              <div>
                                                                                <div><br /></div>
                                                                                <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">The California Code of Regulations defines a <bdt className="block-component" />"resident"<bdt className="statement-end-if-in-editor" /> as:</span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </div>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5', marginLeft: '20px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">(1) every individual who is in the State of California for other than a temporary or transitory purpose and</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5', marginLeft: '20px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">All other individuals are defined as <bdt className="block-component" />"non-residents."<bdt className="statement-end-if-in-editor" /></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If this definition of <bdt className="block-component" />"resident"<bdt className="statement-end-if-in-editor" /> applies to you, we must adhere to certain rights and obligations regarding your personal information.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>What categories of personal information do we collect?</strong></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We have collected the following categories of personal information in the past twelve (12) months:<span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <table style={{ width: '100%' }}>
                                                                                <tbody>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Category</strong></span></span>
                                                                                    </span>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderTop: '1px solid black', borderRight: '1px solid black' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Examples</strong></span></span>
                                                                                    </span>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', borderRight: '1px solid black', borderTop: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Collected</strong></span></span>
                                                                                    </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">A. Identifiers</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderTop: '1px solid black', borderRight: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', verticalAlign: 'middle', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />YES<bdt className="else-block" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">B. Personal information categories listed in the California Customer Records statute</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Name, contact information, education, employment, employment history, and financial information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">C. Protected classification characteristics under California or federal law</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Gender and date of birth</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">D. Commercial information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Transaction information, purchase history, financial details, and payment information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">E. Biometric information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Fingerprints and voiceprints</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">F. Internet or other similar network activity</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Browsing history, search history, online <bdt className="block-component" />behavior<bdt className="statement-end-if-in-editor" />, interest data, and interactions with our and other websites, applications, systems, and advertisements</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">G. Geolocation data</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Device location</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />YES<bdt className="else-block" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">H. Audio, electronic, visual, thermal, olfactory, or similar information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Images and audio, video or call recordings created in connection with our business activities</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ width: '33.8274%', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">I. Professional or employment-related information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '51.4385%', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ width: '14.9084%', textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black', width: '33.8274%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">J. Education Information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ borderRight: '1px solid black', borderTop: '1px solid black', width: '51.4385%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Student records and directory information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid black', borderTop: '1px solid black', width: '14.9084%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor" /></span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                  <tr>
                                                                                    <td style={{ borderWidth: '1px', borderColor: 'black', borderStyle: 'solid', width: '33.8274%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">K. Inferences drawn from other personal information</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ borderBottom: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', width: '51.4385%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</span></span>
                                                                                      </span>
                                                                                      </div>
                                                                                    </td>
                                                                                    <td style={{ textAlign: 'center', borderRight: '1px solid black', borderBottom: '1px solid black', borderTop: '1px solid black', width: '14.9084%' }}>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                      <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" />NO<bdt className="statement-end-if-in-editor"><span data-custom-class="body_text" /></bdt>
                                                                                      </span>
                                                                                      </span>
                                                                                      </span>
                                                                                      </div>
                                                                                      <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                                    </td>
                                                                                  </tr>
                                                                                </tbody>
                                                                              </table>
                                                                              <div style={{ lineHeight: '1.5' }}>
                                                                                <bdt className="block-component" />
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Receiving help through our customer support channels;</span>
                                                                                  <span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Participation in customer surveys or contests; and</span><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Facilitation in the delivery of our Services and to respond to your inquiries.</span>
                                                                                  <span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                  </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>How do we use and share your personal information?</strong></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">More information about our data collection and sharing practices can be found in this privacy notice<bdt className="block-component" />.</span></span>
                                                                                </span>
                                                                                </span><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                  </span>
                                                                                </span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You may contact us <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>by
                                                                                email at </span><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">hello@moodzhub.com</bdt>, <bdt className="statement-end-if-in-editor" /><bdt className="block-component" /></span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                    <bdt className="block-component"><span data-custom-class="body_text" /></bdt></span></span>
                                                                              </span>
                                                                              </span>
                                                                                <span data-custom-class="body_text">or by referring to the contact details at the bottom of this document.</span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are using an <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> agent to exercise your right to opt out we may deny a request if the <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> agent does not submit proof that they have been validly <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> to act on your behalf.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Will your information be shared with anyone else?</strong></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be <bdt className="block-component" />"selling"<bdt className="statement-end-if-in-editor" /> of your personal information.<span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="question">Tycoon Tycoon Company Limited</bdt></span>
                                                                                <span data-custom-class="body_text"> has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. <span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="question">Tycoon Tycoon Company Limited</bdt></span></span>
                                                                                </span>will not sell personal information in the future belonging to website visitors, users, and other consumers.<span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                              </span>
                                                                                <span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong>Your rights with respect to your personal data</strong></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><u>Right to request deletion of the data — Request to delete</u></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><u>Right to be informed — Request to know</u></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Depending on the circumstances, you have a right to know:<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">whether we collect and use your personal information;<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">the categories of personal information that we collect;<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">the purposes for which the collected personal information is used;<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">whether we sell your personal information to third parties;<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">the categories of personal information that we sold or disclosed for a business purpose;<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">the business or commercial purpose for collecting or selling personal information.<span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We will not discriminate against you if you exercise your privacy rights.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><u>Verification process</u></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g.<bdt className="block-component" />,<bdt className="statement-end-if-in-editor" /> phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><u>Other privacy rights</u></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You may object to the processing of your personal information.<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You can designate an <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> agent to make a request under the CCPA on your behalf. We may deny a request from an <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> agent that does not submit proof that they have been validly <bdt className="block-component" />authorized<bdt className="statement-end-if-in-editor" /> to act on your behalf in accordance with the CCPA.<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.<span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">To exercise these rights, you can contact us </span><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" />by email at <bdt className="question">hello@moodzhub.com</bdt>, <bdt className="statement-end-if-in-editor" /><bdt className="block-component" /></span>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                  <bdt className="block-component"><span data-custom-class="body_text" /></bdt></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                                <span data-custom-class="body_text">or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</span>
                                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component"><bdt className="block-component"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor"><bdt className="statement-end-if-in-editor" /></bdt></span></span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                                </bdt></bdt></span>
                                                                                </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="policyupdates" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><em><strong>In Short: </strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <bdt className="block-component" />"Revised"<bdt className="statement-end-if-in-editor" /> date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="contact" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you have questions or comments about this notice, you may <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component" />email us at <bdt className="question">hello@moodzhub.com</bdt><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"> or by post to:</span></span>
                                                                                </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="question">Tycoon Tycoon Company Limited</bdt></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </span><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><bdt className="block-component" /></span></span>
                                                                                </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">No.1 Kharya Thukha Street,</bdt><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span data-custom-class="body_text" style={{ fontSize: '15px' }}><bdt className="question">Yankin Township<span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="statement-end-if-in-editor" /></span></span>
                                                                              </span>
                                                                              </bdt>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="question">Yangon</bdt><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="block-component" /><bdt className="block-component" /> <bdt className="question">11081</bdt><bdt className="statement-end-if-in-editor" /><bdt className="block-component" /><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="block-component" /></span></span>
                                                                              </span>
                                                                                <bdt className="question">Burma</bdt><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                </span>
                                                                                </bdt>
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                                </span>
                                                                                </span><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><bdt className="statement-end-if-in-editor" /></span></span>
                                                                                  </span>
                                                                                </bdt>
                                                                                </span>
                                                                                </span>
                                                                                </span>
                                                                              </span><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)' }}><bdt className="statement-end-if-in-editor"><span style={{ color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="block-component"><bdt className="block-component" /></bdt></span></span>
                                                                              </span>
                                                                              </bdt></span>
                                                                              </span>
                                                                              </span><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><bdt className="statement-end-if-in-editor"><bdt className="block-component" /></bdt></span></span>
                                                                              </span></span></span></span></span></div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div id="request" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </span>
                                                                              </div>
                                                                              <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                              <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please <bdt className="block-component" />submit a request form by clicking </span>
                                                                                <span style={{ color: 'rgb(48, 48, 241)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="https://app.termly.io/notify/32eb505c-e779-4dfc-9981-727d1965f12c" rel="noopener noreferrer" target="_blank">here</a></span></span>
                                                                                </span>
                                                                                <bdt className="block-component"><span data-custom-class="body_text" /></bdt></span></span><span data-custom-class="body_text">.</span>
                                                                              </div>
                                                                              <style dangerouslySetInnerHTML={{ __html: "\n                                            ul {\n                                                list-style-type: square;\n                                            }\n    \n                                            ul>li>ul {\n                                                list-style-type: circle;\n                                            }\n    \n                                            ul>li>ul>li>ul {\n                                                list-style-type: square;\n                                            }\n    \n                                            ol li {\n                                                font-family: Arial;\n                                            }\n                                        " }} />
                                                                            </div>
                                                                            <div style={{ color: '#595959', fontSize: '14px', fontFamily: 'Arial', paddingTop: '16px' }}>
                                                                              This privacy policy was created using Termly's <a style={{ color: 'rgb(48, 48, 241) !important' }} href="https://termly.io/products/privacy-policy-generator/">Privacy Policy Generator</a>.
                                                                            </div>
                                                                          </div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div></div>


      </div>
    </div>
  );
};

export default Terms;
