const faqData = {
  EN: [
    {
      title: "What is MoodzHub app?",
      body: (
        <div>
          MoodzHub app is a mood tracker, a tool to record and track your moods.
          It lets you aware more of your moods and understand better whether
          your mood is positive or negative. You can also write down as a diary.
          reflect yourself and find what the causes.
          <br />
          Disclaimer :{" "}
          <ul>
            <li>
              Moodz Hub app does not provide counselling treatment or
              interventions to address mental illnesses and suicidal ideation.
            </li>
            <li>
              MoodzHub app can be used as a tool by yourself or with the help of
              professional psychologists.
            </li>
            <li>
              If you feel depressed, lonely or suicidal, you can seek help from
              Call Me Today, Mee Pya Tike and other psychological services.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Can you add mood from the previous days?",
      body: <p>Yes. You can choose the time and date to add.</p>,
    },
    {
      title: "Can you edit/delete the moods added?",
      body: <p>Yes, you can.</p>,
    },
    {
      title: "Available on which platform? Android, iOS?",
      body: <p>MoodzHub has Andriod, iOS and Desktop version</p>,
    },
    {
      title: "Where are the data stored when you added into MoodzHub App?",
      body: (
        <p>
          All the data are stored in your phone local storage. If you{" "}
          <b>backup</b> your data, if will be uploaded into cloud storage which
          is encrypted safely. After backup, you can restore your data in your
          new device. If your data are not backed up, it will be stored only in
          your phone.
        </p>
      ),
    },
    {
      title: "Safety of your personal data.",
      body: (
        <p>
          Your personal data (except Profile picture from Facebook login and
          email) are NOT saved by MoodzHub. Data is saved in your own personal
          account. Only you have access and control over it.
        </p>
      ),
    },
  ],
  MM: [
    {
      title: (
        <p>
          <span className="EN EN-header">MoodzHub app</span>&nbsp;ဆိုတာ ဘာ&nbsp;
          <span className="EN EN-header">app</span>&nbsp;လဲ?
        </p>
      ),
      body: (
        <div>
          <span className="EN EN-text">MoodzHub app</span> ဟာ{" "}
          <span className="EN EN-text">mood tracker</span>{" "}
          (ကိုယ့်စိတ်ခံစားချက်များကို မှတ်တမ်းမှတ်ယူနိုင်တဲ့){" "}
          <span className="EN EN-text">app</span> တစ်ခုဖြစ်ပါတယ်။{" "}
          <span className="EN EN-text">MoodzHub</span> ကို အသုံးပြုခြင်းဖြင့်
          သင့်ဖြစ်ပေါ်နေတဲ့ <span className="EN EN-text">mood</span>{" "}
          (ခံစားချက်များကို) ပိုမို သတိထား သိမြင်နိုင်ပြီး မကောင်းသော{" "}
          <span className="EN EN-text">mood</span>၊ အနှုတ်လက္ခဏာဆောင်သော{" "}
          <span className="EN EN-text">mood</span>
          များကို နားလည်စေပါတယ်။ ဖြစ်ပေါ်နေရတဲ့ စိတ်ခံစားချက်တွေရဲ့
          အကြောင်းအရင်းတွေကိုလည်း <span className="EN EN-text">app</span>{" "}
          ထဲမှာမှတ်သားထားတဲ့ မှတ်စုကို ပြန်လည်ကြည့်ပြီး သုံးသပ်နိုင်ပါတယ်။
          <br />
          ရှင်းလင်းချက်
          <ul>
            <li>
              <span className="EN EN-text">MoodzHub app</span> သည် လိုင်စင်ရ
              လေ့ကျင့်ထားသော ကျန်းမာရေးနှင့် စိတ်ပညာပညာရှင်နှင့်
              အစားမထိုးနိုင်ပါ။ စိတ်ရောဂါများအတွက်
              ကုသမှုပေးနိုင်ခြင်းလည်းမရှိပါ။
            </li>
            <li>
              <span className="EN EN-text">MoodzHub app</span> ကို{" "}
              <span className="EN EN-text">tool</span> တစ်ခုအနေဖြင့်လည်း
              စိတ်ပညာရှင်များ၏ အကူအညီ၊ ဆွေးနွေးတိုင်ပင်ချက်များအတိုင်း
              သုံးနိုင်ပါသည်။
            </li>
            <li>
              စိတ်ကျရောဂါအပါအ၀င် တခြားစိတ်ရောဂါများပြင်းပြင်းထန်ထန်ခံစားရပါက
              <span className="EN EN-text">
                Call Me Today, Mee Pya Tike
              </span>{" "}
              နဲ့ တခြား စိတ်ပိုင်းဆိုင်ရာဆွေးနွေးပေးသည့် ပညာရှင်များ၊
              ၀န်ဆောင်မှုများသို့ ဆက်သွယ် အကူအညီရယူနိုင်ပါသည်။
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: (
        <p>
          လွန်ပြီးသားရက်တုန်းက အချိန်မှာဖြစ်ပျက်တဲ့
          <span className="EN EN-header">mood</span> ကို ပြန်ထည့်လို့ရနိုင်လား?
        </p>
      ),
      body: <p>ရပါတယ်! အချိန်၊ နေ့ရက်တွေကို ပြန်ရွေးပြီး ထည့်နိုင်ပါတယ်။</p>,
    },
    {
      title: (
        <p>
          ထည့်ပြီးသား <span className="EN EN-header">mood</span> တွေကို
          ပြန်ပြင်၊ ပြန်ဖျက်တာလုပ်နိုင်လား?
        </p>
      ),
      body: <p>လုပ်နိုင်ပါတယ်</p>,
    },
    {
      title: (
        <p>
          <span className="EN EN-header">Android</span> နဲ့{" "}
          <span className="EN EN-header">iOS</span> ဘယ်မှာရလဲ?
        </p>
      ),
      body: (
        <p>
          ဖုန်းအတွက် <span className="EN EN-text">Android, iOS</span> ၂
          ခုလုံးနဲ့ <span className="EN EN-text">Desktop version</span>{" "}
          မှာရရှိနိုင်ပါတယ်။
        </p>
      ),
    },
    {
      title: (
        <p>
          <span className="EN EN-header">Moodz Hub App</span> ထဲထည့်လိုက်တဲ့{" "}
          <span className="EN EN-header">data</span> အချက်အလက် တွေကို
          ဘယ်မှာသိမ်းထားမှာလဲ?
        </p>
      ),
      body: (
        <p>
          ကိုယ့်သွင်းလိုက်တဲ့ <span className="EN EN-text">mood</span> တွေ
          စာသားတွေအားလုံးက သင့်ဖုန်းထဲမှာသာ သိမ်းထားမှာဖြစ်ပါတယ်။ တကယ်လို့{" "}
          <span className="EN EN-text">Setting</span> မှာ{" "}
          <span className="EN EN-text">Backup</span> ခလုတ်ကို ဖွင့်ထားရင်{" "}
          <span className="EN EN-text">data</span> တွေကို လုံခြုံစွာ{" "}
          <span className="EN EN-text">encrypted</span> ထားတဲ့{" "}
          <span className="EN EN-text">Cloud</span> ထဲမှာရှိနေပြီး နောက်ထပ်{" "}
          <span className="EN EN-text">device</span> အသစ်တစ်လုံးမှာ{" "}
          <span className="EN EN-text">restore</span> လုပ်နိုင်ပါတယ်။{" "}
          <span className="EN EN-text">Restore</span> လုပ်လိုက်ရင်{" "}
          <span className="EN EN-text">device</span> အဟောင်းမှာရှိပြီးသား{" "}
          <span className="EN EN-text">data</span> တွေကို{" "}
          <span className="EN EN-text">device</span> အသစ်မှာပြန်ရနိုင်ပါတယ်။{" "}
          <span className="EN EN-text">Backup</span> ခလုတ်ပိတ်ထားရင်တော့{" "}
          <span className="EN EN-text">mood</span> ထည့်သွင်းလိုက်တဲ့ ဖုန်းမှာသာ
          သိမ်းထားပေးမှာဖြစ်ပါတယ်။
        </p>
      ),
    },
    {
      title: <p>ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ ဘယ်လောက်လုံခြုံပါလဲ?</p>,
      body: (
        <p>
          ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ (
          <span className="EN EN-text">Facebook Login</span> မှ ပုံ၊{" "}
          <span className="EN EN-text">email</span>
          မှလွဲပြီး) <span className="EN EN-text">Moodz Hub</span> မှ လုံး၀
          စုစည်းသိမ်းထားခြင်းမရှိပါဘူး။ <span className="EN EN-text">User</span>{" "}
          များရဲ့ ကိုယ်ပိုင် <span className="EN EN-text">account</span> ထဲမှာသာ
          လုံခြုံစွာသိမ်းထားမှာဖြစ်ပြီး <span className="EN EN-text">user</span>{" "}
          များသာ ရပိုင်ခွင့် ထိန်းချုပ်ပိုင်ခွင့် အပြည့်ရှိပါတယ်။
        </p>
      ),
    },
  ],
};

export default faqData;
